const sources = {
    "talent_help": [],
    "talent_004": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_004/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_004/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_004/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_004/Source 4.pdf"
        },
        {
            "name": "Source 5",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_004/Source 5.pdf"
        }
    ],
    "talent_003": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_003/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_003/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_003/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_003/Source 4.pdf"
        }
    ],
    "talent_035": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_035/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_035/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_035/Source 3.pdf"
        }
    ],
    "talent_032": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_032/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_032/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_032/Source 3.pdf"
        }
    ],
    "talent_033": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_033/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_033/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_033/Source 3.pdf"
        }
    ],
    "talent_034": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_034/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_034/Source 2.pdf"
        }
    ],
    "talent_002": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_002/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_002/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_002/Source 3.pdf"
        }
    ],
    "talent_005": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_005/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_005/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_005/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_005/Source 4.pdf"
        }
    ],
    "talent_042": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_042/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_042/Source 2.pdf"
        }
    ],
    "talent_018": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_018/Source 1.pdf"
        }
    ],
    "talent_027": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_027/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_027/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_027/Source 3.pdf"
        }
    ],
    "talent_020": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_020/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_020/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_020/Source 3.pdf"
        }
    ],
    "talent_029": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_029/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_029/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_029/Source 3.pdf"
        }
    ],
    "talent_016": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_016/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_016/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_016/Source 3.pdf"
        }
    ],
    "talent_011": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_011/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_011/Source 2.pdf"
        }
    ],
    "talent_043": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_043/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_043/Source 2.pdf"
        }
    ],
    "talent_010": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_010/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_010/Source 2.pdf"
        }
    ],
    "talent_028": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_028/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_028/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_028/Source 3.pdf"
        }
    ],
    "talent_017": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_017/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_017/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_017/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_017/Source 4.pdf"
        }
    ],
    "talent_021": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_021/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_021/Source 2.pdf"
        }
    ],
    "talent_019": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_019/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_019/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_019/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_019/Source 4.pdf"
        }
    ],
    "talent_026": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_026/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_026/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_026/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_026/Source 4.pdf"
        }
    ],
    "talent_007": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_007/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_007/Source 2.pdf",
            "trans_link": "https://cset.georgetown.edu/research/notice-of-the-office-of-the-ministry-of-science-and-technology-declaring-the-2019-high-end-foreign-expert-recruitment-plan/"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_007/Source 3.pdf",
            "trans_link": "https://cset.georgetown.edu/research/notice-on-applying-for-2020-national-foreign-expert-projects/"
        }
    ],
    "talent_038": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_038/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_038/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_038/Source 3.pdf",
            "trans_link": "https://cset.georgetown.edu/research/situation-report-on-cas-work-on-the-youth-thousand-talents-program/"
        }
    ],
    "talent_031": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_031/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_031/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_031/Source 3.pdf"
        }
    ],
    "talent_036": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_036/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_036/Source 2.pdf"
        }
    ],
    "talent_009": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_009/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_009/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_009/Source 3.pdf"
        }
    ],
    "talent_037": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_037/Source 1.pdf"
        }
    ],
    "talent_008": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_008/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_008/Source 2.pdf"
        }
    ],
    "talent_030": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_030/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_030/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_030/Source 3.pdf"
        }
    ],
    "talent_006": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_006/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_006/Source 2.pdf"
        }
    ],
    "talent_039": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_039/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_039/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_039/Source 3.pdf",
            "trans_link": "https://cset.georgetown.edu/research/detailed-rules-for-the-thousand-talents-program-high-level-foreign-expert-project/"
        }
    ],
    "talent_001": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_001/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_001/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_001/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_001/Source 4.pdf"
        }
    ],
    "talent_023": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_023/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_023/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_023/Source 3.pdf"
        }
    ],
    "talent_024": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_024/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_024/Source 2.pdf"
        }
    ],
    "talent_012": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_012/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_012/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_012/Source 3.pdf"
        }
    ],
    "talent_015": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_015/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_015/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_015/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_015/Source 4.pdf",
            "trans_link": "https://cset.georgetown.edu/research/2019-chinese-academy-of-sciences-talent-program-application-guide/"
        }
    ],
    "talent_041": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_041/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_041/Source 2.pdf"
        }
    ],
    "talent_014": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_014/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_014/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_014/Source 3.pdf"
        },
        {
            "name": "Source 4",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_014/Source 4.pdf",
            "trans_link": "https://cset.georgetown.edu/research/2019-chinese-academy-of-sciences-talent-program-application-guide/"
        }
    ],
    "talent_013": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_013/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_013/Source 2.pdf"
        }
    ],
    "talent_025": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_025/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_025/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_025/Source 3.pdf"
        }
    ],
    "talent_022": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_022/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_022/Source 2.pdf"
        }
    ],
    "talent_040": [
        {
            "name": "Source 1",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_040/Source 1.pdf"
        },
        {
            "name": "Source 2",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_040/Source 2.pdf"
        },
        {
            "name": "Source 3",
            "link": "https://storage.googleapis.com/public-talent-sources/talent_040/Source 3.pdf"
        }
    ]
};

export { sources }
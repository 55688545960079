const help_data = {"id": "talent_help",
    "name_cn": ["User Guide"],
    "name_en": ["CSET Chinese Talent Program Tracker"],
    "alt_en_names": [""],
    "Type": ["National, Provincial, or Municipal"],
    "Oversight Body (CN)": ["Chinese name of oversight body"],
    "Oversight Body (EN)": ["English name of oversight body"],
    "Year Established": ["Year the program started"],
    "Target Yearly Participants": ["Average number of participants targeted per year"],
    "Status": ["Active, Inactive, or Absorbed. Absorbed in this case means a talent program has been absorbed or merged with another program. More information is included in the \"Additional Information\" section of each \"Absorbed\" entry."],
    "Target Audience": ["Does this program aim to attract Domestic or Overseas talent?"],
    "Chinese Citizenship": ["Required or Not Required. Please note that some domestic Chinese programs do not have a Chinese citizenship requirement."],
    "Intended Sector": ["Does this program aim to funnel talent into Academia, the Commercial sector, the Government sector, or a combination of the three?"],
    "Term Duration": ["Length of time participants are expected to participate"],
    "Residency Requirement": ["Does this program require participants to reside in China? Is this requirement Part Time or Full Time?"],
    "Age Requirement": ["Age range of participants"],
    "Benefits": ["Does the program provide any of the following benefits: Academic Funding, Monetary Award, Networking, Family Assistance, Housing, Living Expenses, Research Funding, Travel Expenses, or Wage (Additional information on benefits can be found in the notes on each card)"]
};

export { help_data }
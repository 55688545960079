import React, { useEffect } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessIcon from '@material-ui/icons/Business';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import InputLabel from '@material-ui/core/InputLabel';
import Link from "@material-ui/core/Link";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PublicIcon from '@material-ui/icons/Public';
import SchoolIcon from '@material-ui/icons/School';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import china from "../images/china.svg";
import passport_red from "../images/passport_red.png";
import passport_white from "../images/passport_white.png";
import help_img from "../images/help.png";
import cset_logo from "../images/cset_logo.svg";
// we don't use the pdf in this code other than linking to it, but we do want it to be part of the artifacts included
// in the built site, which importing it here will do
import pdf from "../static_data/cset_chinese_talent_program_tracker.pdf";
import "../styles/styles.css";
import { program_data } from "../static_data/data.js"
import { dropdown_vals } from "../static_data/dropdowns";
import { sources } from "../static_data/sources";
import { help_data } from "../static_data/help_data";


// entry point of website
export default function Home() {

  useEffect(() => {
    document.title = "CSET Chinese Talent Program Tracker";
    document.documentElement.lang = "en";
  }, []);

  const [visible_data, setFilteredData] = React.useState(JSON.parse(JSON.stringify(program_data)));
  // filters will map keys corresponding to dropdown metadata to arrays of selected items
  const [filters, setFilters] = React.useState({});
  const [help_open, setHelpOpen] = React.useState(false);

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  // respond to changes in the dropdowns, adding and removing items from the filter, and then applying the filter
  const handleChange = (key, val) => {
    const new_filters = {...filters};
    if(val.length > 0){
      new_filters[key] = val;
    } else {
      delete new_filters[key];
    }

    const filtered_data = [];
    for(let record_idx = 0; record_idx < program_data.length; record_idx ++){
      const record = program_data[record_idx];
      // admit will only stay true if at least one value for all filters is matched by the current record
      let admit = true;
      for(let record_prop in new_filters){
        // we will iterate over all selected values of each dropdown and allow records to pass through if they have
        // at least one selected value from each dropdown
        let has_one_value = false;
        // we need to clean oversight body, because we display one dropdown with both en and cn data but maintain the
        // en and cn lists separately in the underlying data
        if(record_prop === "Oversight Body"){
          let en_record_name = record_prop+" (EN)";
          for(let selected_elt_idx = 0; selected_elt_idx < new_filters[record_prop].length; selected_elt_idx++) {
            const selected_elt = new_filters[record_prop][selected_elt_idx];
            let en_value = selected_elt.split("(")[0].trim();
            has_one_value |= record.hasOwnProperty(en_record_name) && record[en_record_name].includes(en_value);
          }
        } else {
          for(let selected_elt_idx = 0; selected_elt_idx < new_filters[record_prop].length; selected_elt_idx++) {
            const selected_elt = new_filters[record_prop][selected_elt_idx];
            has_one_value |= record.hasOwnProperty(record_prop) && record[record_prop].includes(selected_elt);
          }
        }
        admit &= has_one_value;
      }
      if(admit){
        filtered_data.push(record);
      }
    }

    setFilteredData(filtered_data);
    setFilters(new_filters);
  };

  const handleClear = () => {
    setFilteredData(JSON.parse(JSON.stringify(program_data)));
    setFilters({});
  };

  return (
    <div>
      <div id="toolbar" style={{"margin": "20px"}}>
        <a href={"https://cset.georgetown.edu"} target="_blank" title="Link to CSET website, cset.georgetown.edu">
          <img src={cset_logo} style={{"width": "300px"}} alt="CSET Logo"/>
        </a>
        <Button variant="contained"
                color="primary"
                style={{"float": "right"}}
                href="https://docs.google.com/forms/d/e/1FAIpQLSceBUZPTbn0-lTBfJGjb2DoD-rWpOvU1cAw2nFITerRnbWG8Q/viewform?vc=0&c=0&w=1&flr=0&gxids=7757"
                target="_blank">
          Questions and Submissions
        </Button>
      </div>
      <div id="project-description" style={{"margin": "50px 100px"}}>
        <div id="description-header" style={{"marginBottom": "30px"}}>
          <Typography variant={"h4"} gutterBottom>Chinese Talent Program Tracker</Typography>
          <Typography variant={"h6"} gutterBottom>By Emily Weinstein</Typography>
          <Typography variant={"subtitle2"} gutterBottom>Web design by Jennifer Melot</Typography>
        </div>
        <Typography variant={"body1"} paragraph>
        The Chinese Talent Program Tracker is a catalogue of Chinese Party-State-sponsored initiatives aimed at
          cultivating China’s domestic talent pool in support of China's strategic civilian and military goals.
          Viewed by Beijing as vital to Chinese economic and social development, talent programs aim to recruit
          everyone from experts to students of both Chinese and non-Chinese citizenship to fill positions across
          government, industry, defense, and academia to drive the innovation and growth of the Chinese economy.
        </Typography>
        <Typography variant={"body1"} paragraph>
        The information in this catalogue, as described in Chinese documents, resulted from analysis of
          primary Chinese sources publicly available on PRC ministry and government websites, state-owned media
          sources, and Chinese university websites. It is not meant to provide a comprehensive assessment of
          individual talent programs. Rather, it aims to make this information more accessible to the general public
          so they can have a better understanding of the depth and breadth of China’s talent initiatives.
        </Typography>
        <Typography variant={"body1"} paragraph>
          This version of the catalogue includes national-level talent programs. Further iterations will include
          provincial, municipal, and sub-municipal-level programs. A user guide can be found here:
        </Typography>
        <p style={{"margin": "20px 0px"}}>
          <Button variant="contained" color="primary"
                style={{"minWidth": "30px"}} onClick={handleHelpOpen}>
            User Guide
          </Button>
        </p>
        <Typography variant={"body1"} paragraph>
          For more information on this subject, please see
           related <Link href="https://cset.georgetown.edu/article/tag/chinese-talent-programs/" target="_blank">
                CSET translations and publications
            </Link>. You may download the content of this catalog <Link
            href="/static/cset_chinese_talent_program_tracker.pdf" download>here</Link>.
        </Typography>
        <Typography variant={"body2"} paragraph>
          The author is grateful to Alex Bowe and Joy Ma for their expertise. In addition, the author would like
          to thank Igor Mikolic-Torreira, Dewey Murdick, James Dunham, Remco Zwetsloot, Ryan Fedasiuk, Dahlia Peterson,
          Rita Konaev, Melissa Flagg, Anna Puglisi, Rebecca Kagan, Daniel Hague, Alexandra Vreeman, and Lynne Weil
          for their suggestions on style and content, and Rebecca Gelles for a technical review.
        </Typography>
      </div>
      <div style={{"backgroundColor": "#FFFFFF", "padding": "10px 10px", "minHeight": "1000px"}}>
        <div style={{"margin": "10px 15px"}} role="navigation">
          {
            dropdown_vals.map((dd) => (
              <FormControl style={{"marginRight": "30px", "width": "170px"}} key={"fc-"+dd["name"]}>
                <InputLabel id={"label-"+dd["name"].replace(" ", "-")}>
                    <em>{dd["name"]}</em>
                </InputLabel>
                <Select
                    id={"demo-simple-select"+dd["name"].replace(" ", "-")}
                    labelId={"label-"+dd["name"].replace(" ", "-")}
                    value={filters.hasOwnProperty(dd["name"]) ? filters[dd["name"]] : []}
                    key={"demo-simple-select"+dd["name"].replace(" ", "-")}
                    onChange={(e) => handleChange(dd["name"], e.target.value)}
                    style={{padding: "5px 5px"}}
                    multiple displayEmpty
                >
                  {
                    dd["values"].map((val) => (
                        <MenuItem value={val} key={"item-"+val}>{val}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            ))
          }
          <Button variant="contained" color="primary" onClick={(e) => handleClear()}
                  style={{"marginTop": "15px"}}>Clear</Button>

          <Dialog
            open={help_open}
            onClose={handleHelpClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth={"lg"}
          >
            <DialogBody programMetadata={help_data} isHelp={true}/>
          </Dialog>
        </div>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          role="main"
        >
        {
          visible_data.map((program) => (
            <ProgramInfo programMetadata={program} key={program['id']}/>
          ))
        }
        </Grid>
      </div>
    </div>
  )
}

// component corresponding to a baseball card
function ProgramInfo(props){
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKey = (e) => {
    if(e.keyCode === 13){
      handleOpen();
    }
  };

  return (
    <div>
      <div style={{margin: "10px"}}>
        <Card className="program-summary" onClick={handleOpen} onKeyDown={handleKey} tabIndex={0} elevation={3}>
            <SummaryCardBody programMetadata={props.programMetadata}/>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogBody programMetadata={props.programMetadata} isHelp={false}/>
        </Dialog>
      </div>
    </div>
  )
}

// body of the baseball card
function SummaryCardBody(props){
  const year = props.programMetadata["Year Established"].length > 0 ? "Established "+
      props.programMetadata["Year Established"] : "";
  const status = props.programMetadata["Status"].length > 0 ? props.programMetadata["Status"] : "";

  return (
    <div>
      <CardContent style={{height: "235px"}}>
        <Typography color="textSecondary" style={{fontSize: "14px", marginBottom: "15px"}} gutterBottom>
          { props.programMetadata["Type"] }
        </Typography>
        <Typography style={{fontSize: "15px", textAlign: "center", fontWeight: "600", marginBottom: "5px"}}>
          { props.programMetadata["name_en"] }
        </Typography>
        <Typography style={{fontSize: "14px", textAlign: "center"}} lang="zh">
          { props.programMetadata["name_cn"] }
        </Typography>
        <Typography color="textSecondary" style={{fontSize: "14px", textAlign: "center", marginTop: "15px"}}>
          { status }{ year.length > 0 && status.length > 0 ? <br/>: "" }{ year }
        </Typography>
      </CardContent>
      <SummaryCardFooter programMetadata={props.programMetadata}/>
    </div>
  )
}

// footer of the baseball card
function SummaryCardFooter(props){
  // select what citizenship icon to display
  let citizenship = <Tooltip title="Citizenship Requirement Unknown">
    <HelpIcon alt="Citizenship Requirement Unknown"/></Tooltip>;
  if (props.programMetadata["Chinese Citizenship"].length !== 0){
    if(props.programMetadata["Chinese Citizenship"].includes("Required")){
      citizenship = <Tooltip title="Chinese Citizenship Required">
        <img src={passport_red} alt="Chinese Citizenship Required" style={{height: "24px"}}/></Tooltip>;
    } else{
      citizenship = <Tooltip title="Chinese Citizenship Not Required">
        <img src={passport_white} alt="Chinese Citizenship Not Required" style={{height: "24px"}}/></Tooltip>;
    }
  }

  // select what audience icon(s) to display
  const audience_icons = [];
  const audience_string = props.programMetadata["Target Audience"];
  if(audience_string.includes("Overseas")){
    audience_icons.push(<Tooltip title="Target Audience: Overseas" key="tt-audience-overseas">
      <PublicIcon alt="Target Audience: Overseas" key="icon-audience-overseas"/></Tooltip>)
  }
  if(audience_string.includes("Domestic")){
    audience_icons.push(
        <Tooltip title="Target Audience: Domestic" key="tt-audience-domestic">
          <img src={china} alt="Target Audience: Domestic" key="icon-audience-domestic" style={{height: "24px"}}/>
        </Tooltip>
    )
  }
  if(audience_icons.length === 0){
    audience_icons.push(<Tooltip title="Target Audience: Unknown" key="tt-audience-unknown">
      <HelpIcon alt="Target Audience: Unknown" key="icon-audience-unknown"/></Tooltip>)
  }

  // select what sector icon(s) to display
  const sector_icons = [];
  const sector_string = props.programMetadata["Intended Sector"];
  if(sector_string.includes("Academia")){
    sector_icons.push(<Tooltip title="Sector: Academia" key="tt-sector-academia">
      <SchoolIcon alt="Sector: Academia" key="icon-sector-academia"/></Tooltip>);
  }
  if(sector_string.includes("Commercial")){
    sector_icons.push(<Tooltip title="Sector: Commercial" key="tt-sector-commercial">
      <BusinessIcon alt="Sector: Commercial" key="icon-sector-commercial"/></Tooltip>);
  }
  if(sector_string.includes("Government")){
    sector_icons.push(<Tooltip title="Sector: Government" key="tt-sector-government">
      <AccountBalanceIcon alt="Sector: Government" key="icon-sector-government"/></Tooltip>);
  }
  if(sector_icons.length === 0){
        sector_icons.push(<Tooltip title="Sector: Unknown" key="tt-sector-unknown">
          <HelpIcon alt="Sector: Unknown" key="icon-sector-unknown"/></Tooltip>)
  }

  return (
    <div style={{textAlign: "center", marginTop: "5px"}}>
      <span className="summary-icon">{ audience_icons.map(icon => { return icon }) }</span>
      <span className="summary-icon">{ citizenship }</span>
      <span className="summary-icon">{ sector_icons.map(icon => {return icon }) }</span>
    </div>
  )
}

// component for the popup view
function DialogBody(props){
    const col_ordering = [
      "Oversight Body (EN)",
      "Oversight Body (CN)",
      "Status",
      "Chinese Citizenship",
      "Type",
      "Year Established",
      "Target Yearly Participants",
      "Target Audience",
      "Intended Sector",
      "Term Duration",
      "Residency Requirement",
      "Age Requirement",
      "Benefits",
  ];

  return (
    <div>
      <DialogTitle id="simple-dialog-title" style={{textAlign: "center"}}>
        {props.programMetadata["name_en"]}<br/><span lang="zh">{props.programMetadata["name_cn"]}</span>
      </DialogTitle>
      { props.isHelp && <div>
        <Tooltip  title="Help image showing how to interpret the icons.">
          <div style={{"textAlign": "center"}}>
            <img src={help_img} style={{"width": "70%"}} alt="Description of how to interpret the icons"/>
          </div>
        </Tooltip><p style={{"textAlign": "center", "fontWeight": "bold"}}>Please note that multiple values can be
        selected within a dropdown. Click to select a value, and click again to unselect it.</p></div>}
      <div style={{"marginLeft": "5px", "marginRight": "5px"}}>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small" aria-label="program metadata">
            <TableBody>
              {col_ordering.map((col_name) => (
                props.programMetadata[col_name].length > 0 ?
                <TableRow key={col_name}>
                  <TableCell component="th" scope="row">
                    {col_name}
                  </TableCell>
                  <TableCell align="left" lang={col_name.includes("CN") ? "zh" : "en"}>
                    {props.programMetadata[col_name].length === 1 ? props.programMetadata[col_name] :
                        props.programMetadata[col_name].join("; ")}
                  </TableCell>
                </TableRow> : ""
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{"margin": "15px"}}>
          {("notes" in props.programMetadata) && (props.programMetadata["notes"].length > 0) &&
            <div>
              <Typography color="textSecondary" style={{"textAlign": "center", "fontWeight": "bold"}} gutterBottom>
               Additional Information
              </Typography>
              <Typography className="program-type" color="textSecondary" gutterBottom>
                <div dangerouslySetInnerHTML={{ __html: props.programMetadata["notes"]}}/>
              </Typography>
          </div>}
        </div>
        <div style={{"textAlign": "center", "marginBottom": "15px"}}>
          {sources[props.programMetadata["id"]].map(source => (
            <span style={{"margin": "0px 5px"}}><Link href={source.link} variant="body2"
                                                      target="_blank" key={source.name}>
              {source.name}
            </Link>{
              source.hasOwnProperty("trans_link") ? <span>&nbsp;
                <Link href={source.trans_link} variant="body2" target="_blank"
                      key={source.name+"-trans"}>(translation)</Link>
                </span> : ""}
            </span>))}
        </div>
      </div>
    </div>
  )
}
